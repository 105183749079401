header {
    height: 100vh;
    padding: 7rem;
    overflow: hidden;
    /* background-color: rgb(0, 0, 0, 0.3); */
    background-size: cover;
    background-attachment: fixed;
}

.transparent__background {
    text-align: center;
}

.baby__background {
    display: inline-block;
    text-align: left;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ====================== CTA ========================== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}

/* ====================== Header Socials ========================== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    padding-left: 10px;
}

/* .header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
} */
.big__size {
    height: 1em;
    width: 1em;
    transition: .4s;
    margin-bottom: 1em;
    margin-left: 1em;
}

.big__size:hover{
    height: 2em;
    width: 2em;
    transition: .4s;
    margin-bottom: 0em;
    margin-left: 0em;
}


/* ====================== ME ========================== */
.me {
    background: linear-gradiant(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/* ====================== scroll Down ========================== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: .2rem;
}

.scroll__down:hover {
    margin-right: 0em;
    font-size: 1.1rem;
}

/* ===================== MEDIA QUERIES (MEdium Devices) =====================*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;

    }
}

/* ===================== MEDIA QUERIES (small Devices) =====================*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}